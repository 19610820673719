let overlayContainer;
let openSection = '';
let iframe;
let player;
let currentPlayer = null;

window.addEventListener('DOMContentLoaded', (event) => {

	const navItems = document.getElementsByClassName("nav-item");
	const closeButton = document.getElementById("close");
	closeButton.addEventListener("click", onCloseClick);

	for(let i = 0; i < navItems.length; i++) {
		navItems[i].addEventListener('click', onNavClick);
	};

	overlayContainer = document.getElementById("overlay-container");
	
});

function onNavClick(e) {
	e.preventDefault();
	console.log("on nav click");

	overlayContainer.classList.add("open");

	// get overlay target:
	const overlayContent = document.getElementById(e.target.rel);

	openSection = overlayContent;

	TweenLite.to(overlayContainer, 0.9, {opacity: 1, onComplete: () => {
		// reveal content:
		overlayContent.classList.add("open");
		TweenLite.to(overlayContent, 0.6, {opacity:1});
	}});

	// if this view has a video player, get the player object:
	if (e.target.rel === 'reel' || e.target.rel === 'generator') {
		let iframe = document.getElementById(e.target.rel + '-video');
		if(iframe !== null) {
			let player = new Vimeo.Player(iframe);

			currentPlayer = player;
		}
	}


}

function onCloseClick(e) {
	e.preventDefault();

	if(currentPlayer !== null) {
		currentPlayer.pause();
		currentPlayer.setCurrentTime(0);
	}

	currentPlayer = null;

	TweenLite.to(openSection, 0.4, {opacity: 0, onComplete: () => {
		TweenLite.to(overlayContainer, 0.4, {opacity: 0, onComplete: () => {
			overlayContainer.classList.remove("open");
		}})
	}});

}